import { Bar, mixins } from 'vue-chartjs';
import { mapState } from 'vuex';

const { reactiveProp } = mixins;

export default {
  extends: Bar,

  mixins: [reactiveProp],

  props: ['options'],

  computed: {
    ...mapState('settings', ['darkTheme']),
  },

  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object

    this.renderChart(this.chartData, {
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          beforeLabel(tooltipItem, data) {
            return [
              `Praleido valandų: ${data.datasets[0].hours[tooltipItem.index]}`,
              `Uždarė taškų: ${data.datasets[0].points[tooltipItem.index]}`,
            ];
          },
        },
        displayColors: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: this.darkTheme ? 'white' : 'black',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontColor: this.darkTheme ? 'white' : 'black',
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    });
  },
};
