<template>
  <div>
    <div class="d-flex align-center justify-end px-4">
      <ProjectTimeSpentStatisticsFilter
        :filter-params="projectTimeSpentStatisticsFilterParams"
        :sprints="sprints"
        @change="$router.push({ name: 'projectTimeSpentStatistics', query: $event })"
        @reset="resetProjectTimeSpentStatisticsFilters"
      />
    </div>

    <div class="pa-3 text-center">
      <v-progress-circular v-if="!showChart" color="primary" class="mb-4" indeterminate />
      <BarChart
        v-else
        :chart-data="chartData"
        :class="{ 'content-loading': loading['get:api/project-user-stories/statistics'] }"
        class="mx-auto"
      />
    </div>

    <v-row class="px-4">
      <v-col v-if="totalHoursSpent" cols="12" sm="4" class="text-center">
        <span class="text-subtitle-2">{{ $t('projects.labels.hours_passed') }}: </span>
        {{ totalHoursSpent }}
      </v-col>

      <v-col v-if="totalCompletedPoints" cols="12" sm="4" class="text-center">
        <span class="text-subtitle-2">{{ $t('projects.labels.total_points_closed') }}: </span>
        {{ totalCompletedPoints }}
      </v-col>

      <v-col v-if="averageEfficiency" cols="12" sm="4" class="text-center">
        <span class="text-subtitle-2">{{ $t('projects.labels.average_effectiveness') }}: </span>
        {{ averageEfficiency }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProjectTimeSpentStatisticsFilter from '@/components/filters/ProjectTimeSpentStatisticsFilter';
import { mapActions, mapGetters, mapState } from 'vuex';
import { getSanitizedFilterParams } from '@/util/filter-params';
import projectSprintService from '@/api/project-sprint-service';
import { getDefaultProjectTimeSpentStatisticsFilterParams } from '@/store/modules/project-time-spent-statistics-module';
import BarChart from '@/charts/BarChart';

export default {
  name: 'ProjectTimeSpentStatistics',

  components: { BarChart, ProjectTimeSpentStatisticsFilter },

  props: {
    projectId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      showChart: false,
      sprints: [],
    };
  },

  computed: {
    ...mapState('projectTimeSpentStatistics', [
      'projectTimeSpentStatistics',
      'projectPointStatistics',
      'projectTimeSpentStatisticsFilterParams',
    ]),
    ...mapGetters(['loading']),
    ...mapGetters('projectUserStories', ['projectUserStoryPointTypes']),

    totalHoursSpent() {
      let hours = 0;

      for (let i = 0; i < this.projectTimeSpentStatistics[0]?.sprints[0].users.length; i++) {
        const user = this.projectTimeSpentStatistics[0].sprints[0].users[i];
        hours += +user.time_spent;
      }

      return hours;
    },

    totalCompletedPoints() {
      let points = 0;

      for (let i = 0; i < this.projectPointStatistics[0]?.assignees.length; i++) {
        const user = this.projectPointStatistics[0].assignees[i];
        points = 0;

        for (let j = 0; j < this.projectUserStoryPointTypes.length; j++) {
          points += +user[this.projectUserStoryPointTypes[j].completedPointsFieldKey];
        }
      }
      return points;
    },

    averageEfficiency() {
      if (!this.totalCompletedPoints || !this.totalHoursSpent) {
        return 0;
      }

      let averageEfficiency;
      if (this.totalCompletedPoints >= this.totalHoursSpent) {
        averageEfficiency = (this.totalCompletedPoints / this.totalHoursSpent).toFixed(2);
      } else {
        averageEfficiency = ((this.totalHoursSpent / this.totalCompletedPoints) * -1).toFixed(2);
      }
      return averageEfficiency;
    },

    chartData() {
      const users = {};
      const efficiency = {};

      for (let i = 0; i < this.projectTimeSpentStatistics[0]?.sprints[0].users.length; i++) {
        const user = this.projectTimeSpentStatistics[0].sprints[0].users[i];
        users[user.user_id] = user.full_name;
        efficiency[user.user_id] = {
          hours: +user.time_spent,
        };
      }

      let totalPoints;
      for (let i = 0; i < this.projectPointStatistics[0]?.assignees.length; i++) {
        const user = this.projectPointStatistics[0].assignees[i];
        totalPoints = 0;

        for (let j = 0; j < this.projectUserStoryPointTypes.length; j++) {
          const points = this.projectUserStoryPointTypes[j];
          totalPoints += +user[points.completedPointsFieldKey];
        }

        if (users[user.user_id]) {
          efficiency[user.user_id].points = totalPoints;
        } else {
          users[user.user_id] = user.full_name;
          efficiency[user.user_id] = {
            points: totalPoints,
          };
        }
      }

      const labels = [];
      const points = [];
      const hours = [];
      const positiveData = [];
      const negativeData = [];

      Object.keys(efficiency).forEach((userID) => {
        if (efficiency[userID].hours && efficiency[userID].points) {
          labels.push(users[userID]);
          points.push(efficiency[userID].points);
          hours.push(efficiency[userID].hours);
          if (efficiency[userID].points > efficiency[userID].hours) {
            positiveData.push((efficiency[userID].points / efficiency[userID].hours).toFixed(2));
            negativeData.push(0);
          } else if (efficiency[userID].points < efficiency[userID].hours) {
            positiveData.push(0);
            negativeData.push(
              ((efficiency[userID].hours / efficiency[userID].points) * -1).toFixed(2)
            );
          } else {
            positiveData.push(0);
            negativeData.push(0);
          }
        }
      });

      return {
        labels,
        datasets: [
          {
            label: this.$t('projects.labels.effectiveness'),
            backgroundColor: this.$vuetify.theme.currentTheme.error,
            points,
            hours,
            data: negativeData,
          },
          {
            label: this.$t('projects.labels.effectiveness'),
            backgroundColor: this.$vuetify.theme.currentTheme.success,
            points,
            hours,
            data: positiveData,
          },
        ],
      };
    },
  },

  async created() {
    if (this.projectTimeSpentStatistics.length) {
      this.showChart = true;
    }
    this.fetchProjectSprints();
    await this.fetchProjectTimeSpentStatistics(getSanitizedFilterParams(this.$route.query));
    this.showChart = true;
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.projectTimeSpentStatisticsFilterParams) !== JSON.stringify(params) &&
      to.name === 'projectTimeSpentStatistics'
    ) {
      this.fetchProjectTimeSpentStatistics(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('projectPointStatistics', ['fetchProjectPointStatistics']),
    ...mapActions('projectTimeSpentStatistics', ['fetchProjectTimeSpentStatistics']),

    async fetchProjectSprints() {
      const { data } = await projectSprintService.getAll({ project_id: this.projectId });
      this.sprints = data;
    },

    resetProjectTimeSpentStatisticsFilters() {
      const defaultFilters = getDefaultProjectTimeSpentStatisticsFilterParams();
      if (
        JSON.stringify(defaultFilters) ===
        JSON.stringify(this.projectTimeSpentStatisticsFilterParams)
      ) {
        return;
      }
      this.$router.push({ name: 'projectTimeSpentStatistics', query: defaultFilters });
    },
  },
};
</script>

<style scoped></style>
